<template>
  <v-card :loading="loading">
    <v-card-title
      >Poultry
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <base-subheading>
        <p class="grey--text body-2 font-weight-light">
          Select poultry on the list
        </p>
      </base-subheading>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="poultry"
        :items-per-page="5"
        :search="search"
        :single-select="singleSelect"
        item-key="id"
        show-select
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      singleSelect: true,
      selected: [],
      search: "",
      loading:false,
      headers: [
        {
          text: "Commodity",
          value: "desc",
        },
        { text: "Variety", value: "variety" },
      ],
      poultry: [],
    };
  },
  watch: {
    selected: function (val) {
      this.onSelect(val);
    },
  },
  computed: {
    ...mapGetters(["poultry/data"]),
    ...mapActions(["poultry/getData"]),
  },
  mounted() {
    this.getPoultry();
  },

  methods: {
    async getPoultry() {
      this.loading=true;
      await this["poultry/getData"];
      this.poultry = await this["poultry/data"];
      this.loading=false;
    },

    onSelect(val) {
      this.$store.dispatch("poultry/onSelected", val[0]);
      this.$router.push({ name: "Poultry Details" });
    },
  },
};
</script>