<template>
  <v-container id="panels" fluid tag="section">
    <v-row>
      <v-col class="mx-auto" cols="12" md="8">
        <base-material-tabs
          v-model="tab"
          background-color="transparent"
          centered
          color="warning"
          icons-and-text
        >
          <v-tab v-for="(tab, i) in tabs3" :key="i">
            {{ tab.text }}
            <v-icon v-text="tab.icon" />
          </v-tab>

          <v-tabs-items v-model="tab" class="pt-12 transparent">
            <v-tab-item>
              <crops></crops>
            </v-tab-item>

            <v-tab-item>
             <livestock></livestock>
            </v-tab-item>

            <v-tab-item>
              <poultry></poultry>
            </v-tab-item>

           
          </v-tabs-items>
        </base-material-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import crops from "../components/crops.vue";
import livestock from "../components/livestock.vue"
import poultry from "../components/poultry.vue"


export default {
  name: "AgriculturalCommodities",

components:{
    crops,
    livestock,
    poultry
},
  data: () => ({
    tab: 0,
    tabs3: [
      {
        text: "Crops",
        icon: "mdi-sprout",
      },
      {
        text: "Livestock",
        icon: "mdi-cow",
      },
      {
        text: "Poultry",
        icon: "mdi-duck",
      },
    ],
  }),
};
</script>
