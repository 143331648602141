<template>
  <v-card :loading="loading">
    <v-card-title
      >Crops
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <base-subheading>
        <p class="grey--text body-2 font-weight-light">
          Select crops on the list
        </p>
      </base-subheading>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="crops"
        :items-per-page="5"
        :search="search"
        :single-select="singleSelect"
        item-key="id"
        show-select
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      singleSelect: true,
      selected: [],
      loading:false,
      search: "",
      headers: [
        {
          text: "Commodity",
          value: "cropclass",
        },
        { text: "Variety", value: "cropdesc" },
      ],
      crops: [],
    };
  },
  watch: {
    selected: function (val) {
      this.onSelect(val);
    },
  },

  computed: {
    ...mapGetters(["crops/data"]),
    ...mapActions([
      "crops/getData", 
      "crops/onSelected"
    ]),
  },
  mounted() {
    this.getCrops();
  },

  methods: {
    async getCrops() {
      this.loading=true;
      await this["crops/getData"];
      this.crops = this["crops/data"];
      this.loading=false;
    },

    async onSelect(val) {
      this.$store.dispatch("crops/onSelected", val[0]);
      this.$router.push({ name: "Crop Details" });
    },
  },
};
</script>